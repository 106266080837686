import { SelectionChangedEvent } from "ag-grid-community";
import { INITIAL_MAN_CHARGE_CNTR_COL_DEF, transferRowData } from "presentation/constant/ManualCharge/ManChargeCntrColumnDefinition";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useManualChargeDetailCntrVM } from "presentation/hook/ManualCharge/useManualChargeDetailCntrVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useManualChargeDetailTracked } from "presentation/store/ManualCharge/ManualChargeDetailProvider";
import { useCallback, useEffect, useRef, useState } from "react";
import { HPHButton, HPHTable, Loader } from "veronica-ui-component/dist/component/core";
import { StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

export const ManualChargeCntrTablePanel = () => {
  const [ manualChargeDtlState ] = useManualChargeDetailTracked();
  const manualDetailCntrVM = useManualChargeDetailCntrVM();
  const [anaInfoState] = useANAInfoTracked();
  const {allowUpdate} = anaInfoState;
  //const { manualChargeDetailCntrs } = manualChargeDtlState;
  const { currentSelectedCntrs, isShowCntrInfoPanel } = manualChargeDtlState.manualChargeDetailCntrState;
  const gridRef: any = useRef();
  const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const messageBarVM = useMessageBarVM();
  const selectManChgDtl = manualChargeDtlState.viewState.currentSelectedData;

const handleApply = useCallback(async() => {    
    if(currentSelectedCntrs?.length <= 0){
        messageBarVM.showWarining('Please select a record(s) to apply.');
        return ;
    }
    
    setIsLoading(true);
    manualDetailCntrVM.apply(currentSelectedCntrs, selectManChgDtl, selectManChgDtl.initManChargeDtlCntrs).then((data) => {
            if (data && data["applyManualChargeCntrFail"]) {
                messageBarVM.showError(data["applyManualChargeCntrFail"]?.toString());
            }      
        setIsLoading(false);
    }).catch(error => {
        setIsLoading(false);
    })    
}, [currentSelectedCntrs, manualDetailCntrVM, selectManChgDtl, messageBarVM]);

const isDisableApply = () => {
    return currentSelectedCntrs.length === 0;
}

const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
    setOnTableSelectionClicked(true);
    const selectedRows = e.api.getSelectedRows();
    manualDetailCntrVM.updateSelectedCharges(selectedRows);
}, [manualDetailCntrVM])

useEffect(() => {
    if (!onTableSelectionClicked) return;

    gridRef.current?.gridRef.current.api?.deselectAll();
}, [onTableSelectionClicked])

useEffect(() => {
    const columnDefs = (INITIAL_MAN_CHARGE_CNTR_COL_DEF.slice());
        
    gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
    if (!onTableSelectionClicked) {
        gridRef.current?.gridRef.current.api?.deselectAll();
    }
})

/*const copyVesselClicked = useCallback((isArrival: boolean) => {
    if(currentSelectedCntrs?.length !== 1){
        messageBarVM.showWarining('Please select a record.');
        return;
    }

   manualDetailCntrVM.copyVesselInfo(currentSelectedCntrs[0], isArrival);    
},[currentSelectedCntrs, manualDetailCntrVM, messageBarVM])

const isDisableCopyVsl = (isArrival: boolean) => {
    if(currentSelectedCntrs.length === 1){
        const selectRow = currentSelectedCntrs[0];

        if(isArrival && selectRow.inHandlingTerminal && selectRow.inVesselCode 
                && selectRow.inVoyageCode){
            return false;
        }

        if(!isArrival && selectRow.outHandlingTerminal && selectRow.outVesselCode 
                && selectRow.outVoyageCode){
            return false;
        }
    }         
    return true;
}*/

return <div className="side-form-content-left-wrapper" style={{height:"73vh", overflow:"auto", }}>
    {(isLoading) && <Loader Indicator="Spinner" size="Medium" /> }
    <HPHTable
        id='manual-charge-dtl-cntr-records-table'
        isNewColumnSetting={true}
        columns={INITIAL_MAN_CHARGE_CNTR_COL_DEF.slice()}
        data={transferRowData(selectManChgDtl.manChgDtlCntrs??[])}
        //onRowDoubleClick={(e: any) => handleRowClick(e.data)}
        showPaginator={false}
        editable={false}
        showAddIcon={false}
        showDeleteButton={false}        
        showReloadIcon={false}
        isScrollHighlighted={true}
        selectionMode={false}
        rowSelection={'multiple'}
        //showActionButtons={true}
        onSelectionChanged={handleSelectionChange}
        gridHeight="customHeight"
        customHeight="calc(59vh)" 
        ref={gridRef}
    />    
        {
          // <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center", justifyContent: "flex-end"}}>
            <StyledAction className="tm-animated-wrapper">
                {/* <HPHButton label={WorkspaceConstant.Common.BUTTON_CLOSE} size={'Small'} theme={'Secondary'} onClick={handleClose}/>                 
                <HPHButton label={WorkspaceConstant.Common.BUTTON_COPYARRIVALVESSEL} disabled={isDisableCopyVsl(true)} size={'Small'} theme={'Secondary'} onClick={() => copyVesselClicked(true)} />                
                <HPHButton label={WorkspaceConstant.Common.BUTTON_COPYDEPATUREVESSEL} disabled={isDisableCopyVsl(false)} size={'Small'} theme={'Secondary'} onClick={() => copyVesselClicked(false)} />*/}
                {(allowUpdate) && <HPHButton disabled={isDisableApply() || isShowCntrInfoPanel} label={WorkspaceConstant.Common.BUTTON_CONFIRM} size={'Small'} theme={'Primary'} onClick={handleApply} />}                
            </StyledAction>
          // </Sidebarheader>
        }
  </div>
}