import { ManualChargeDetailModel } from "presentation/model/ManualChargeMaintenance/ManualChargeDetailModel";
import { Dispatch, SetStateAction } from "react";
import BaseViewModel from "../BaseViewModel";
import { ChargeDetailRepository } from "domain/repository/ChargeDetailMaintenance/ChargeDetailRepo";
import { ChargeDetailEntity } from "domain/entity/ChargeDetailMaintenance/ChargeDetailEntity";


interface ManualChargeDetailVesselVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<ManualChargeDetailModel>> | ((value: SetStateAction<ManualChargeDetailModel>) => void),
    ],
    chargeDetailRepo: ChargeDetailRepository
    
}

export const ManualChargeDetailVesselVM = ({dispatch, chargeDetailRepo
}:ManualChargeDetailVesselVMProps) => {
    const [manualChargeDetailDispatch] = dispatch;  

    const closeCntrCompDialog = () => {
        manualChargeDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowFindVesselPanel: false,
                viewState: {
                    ...prevState.viewState,
                    isRead: !prevState.isShowFindVesselPanel,
                }
            }
        })
    }

    const onShowPanel = (vesselCntrNos: string) => {
        manualChargeDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowFindVesselPanel: true,
                vesselCntrNos: vesselCntrNos,
                viewState: {
                    ...prevState.viewState,
                    isRead: !prevState.isShowFindVesselPanel,
                }
            }
        })
    }

    const initSearch = async(vesselCntrNos: any) => {       
        if(vesselCntrNos){
            return await chargeDetailRepo.getConVslVoy(vesselCntrNos).then(data => {
                if (data && data.toString().startsWith("Error:")) {
                    manualChargeDetailDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: {"initManualChargeCntrFail": data.toString()}
                        };
                    });
                    return {"initManualChargeCntrFail": data.toString()};
                } else {
                    manualChargeDetailDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: {"initManualChargeCntrSuccess":"successful"},
                            manualChargeDetailVessels: data,
                        };
                    });
                    return {"initManualChargeCntrSuccess":"successful"};
                }
            }).catch(error => {
                manualChargeDetailDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: {"initManualChargeCntrFail": error.message}                        
                    }
                });
                return {"initManualChargeCntrFail": error.message};
            })
        }
    }

    const apply = async(selectedRows:ChargeDetailEntity[]) => {
        manualChargeDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowFindVesselPanel: false,
                viewState: {
                    ...prevState.viewState,
                    editingHeader: {
                        ...prevState.viewState.editingHeader,
                        consortiumCode: selectedRows[0]?.consortiumCode!==undefined ?selectedRows[0]?.consortiumCode:null,
                        vesselCode: selectedRows[0]?.vesselCode!==undefined ?selectedRows[0]?.vesselCode:null,
                        voyageCode: selectedRows[0]?.voyageCode!==undefined ?selectedRows[0]?.voyageCode:null,
                    },
                    isRead: !prevState.isShowFindVesselPanel
                }
            }
        })
    }

    const coVslVoy = async(selectedRows:ChargeDetailEntity[]) => {
        manualChargeDetailDispatch(prevState => {
            return {
                ...prevState,
                viewState: {
                    ...prevState.viewState,
                    editingHeader: {
                        ...prevState.viewState.editingHeader,
                        consortiumCode: selectedRows[0]?.consortiumCode!==undefined ?selectedRows[0]?.consortiumCode:null,
                        vesselCode: selectedRows[0]?.vesselCode!==undefined ?selectedRows[0]?.vesselCode:null,
                        voyageCode: selectedRows[0]?.voyageCode!==undefined ?selectedRows[0]?.voyageCode:null,
                    },
                }
            }
        })
    }

    const updateSelectedManualChargeDetails = (rows:any[]) => {
        manualChargeDetailDispatch(prevState => {
            return {
                ...prevState,
                selectedManualChargeDetailVesselRow: rows                       
            }
        })
    }

    return {
        onShowPanel: onShowPanel,
        initSearch: initSearch,
        apply: apply,
        coVslVoy: coVslVoy,
        closeCntrCompDialog: closeCntrCompDialog,
        updateSelectedManualChargeDetails:updateSelectedManualChargeDetails
    }
}


