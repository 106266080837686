import { EMPTY_MANUAL_CHARGE_DETAIL_CNTR_ENTITY, ManualChargeDetailCntrEntity } from "domain/entity/ManualCharge/ManualChargeDetailCntrEntity";
import { ManualChargeDetailCntrRequestEntity } from "domain/entity/ManualCharge/ManualChargeDetailCntrRequestEntity";
import { ManualChargeDetailEntity } from "domain/entity/ManualCharge/ManualChargeDetailEntity";
import { VesselVoyageMasterRepository } from "domain/repository/ChargeDetailMaintenance/VesselVoyageMasterRepo";
import { ManualChargeDetailRepository } from "domain/repository/ManualCharge/ManualChargeDetailRepo";

import { MasterDataType } from "domain/entity/MasterData/MasterDataEntity";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import { ParameterDetailRepository } from "domain/repository/parameter/ParameterDetailRepo";
import _ from "lodash";
import { manChgDtl2dpNumberFieldList, manChgDtl4dpNumberFieldList, manChgDtlPositiveIntegerNumberFieldList } from "presentation/constant/ManualCharge/ManualChargeConstant";
import { EMPTY_MANUAL_CHARGE_CNTR_SEARCH_CRITERIA, ManualChargeCntrSearchCriteria } from "presentation/constant/ManualCharge/ManualChargeHeaderCntrSearchCriteria";
import { DropdownProps } from "presentation/model/DropdownProps";
import { ManualChargeDetailModel } from "presentation/model/ManualChargeMaintenance/ManualChargeDetailModel";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface ManualChargeDetailCntrVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<ManualChargeDetailModel>> | ((value: SetStateAction<ManualChargeDetailModel>) => void),
    ],
    manualChargeDetailRepo: ManualChargeDetailRepository,
    vesselVoyageMasterRepo: VesselVoyageMasterRepository,
    parameterDetailRepo: ParameterDetailRepository,
    masterDataRepo: MasterDataRepository
}

export const ManualChargeDetailCntrVM = ({dispatch, manualChargeDetailRepo, vesselVoyageMasterRepo, parameterDetailRepo, masterDataRepo
}:ManualChargeDetailCntrVMProps) => {
    const [manualChargeDetailDispatch] = dispatch;  

    const onCoVslVoyChange = (inputData: { co?: string, vsl?: string, voy?: string }, fieldName: { co: string, vsl: string, voy: string }) => {
        manualChargeDetailDispatch(prevState => ({
            ...prevState,
            manualChargeDetailCntrState: {
                ...prevState.manualChargeDetailCntrState,
                searchCriteria: {
                    ...prevState.manualChargeDetailCntrState.searchCriteria,
                    [fieldName.co]: inputData?.co?.toUpperCase().replace(/\s+/g, ''),
                    [fieldName.vsl]: inputData?.vsl?.toUpperCase().replace(/\s+/g, ''),
                    [fieldName.voy]: inputData?.voy?.toUpperCase().replace(/\s+/g, ''),
                }
            }
        }))
    }

    const onMultipleDropdownChange = (e: any, fieldName: string) => {
        manualChargeDetailDispatch(prevState => {
            return {
                ...prevState,
                manualChargeDetailCntrState: {
                    ...prevState.manualChargeDetailCntrState,
                    searchCriteria: {
                        ...prevState.manualChargeDetailCntrState.searchCriteria,
                        [fieldName]: _.uniq(e?.map((item: DropdownProps) => item.value)),
                    }
                }
            }
        });
    };

    const onInputTextChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {        
        manualChargeDetailDispatch(prevState => ({
            ...prevState,
            manualChargeDetailCntrState: {
                ...prevState.manualChargeDetailCntrState,
                searchCriteria: {
                    ...prevState.manualChargeDetailCntrState.searchCriteria,
                    [fieldName]: e.target.value.toUpperCase().replace(/\s+/g, ''),
                }
            }
        }))
    };

    const onDateRangeChange = (startDate: any, endDate: any,  dateFields:{startField:string, endField: string}) => {
        manualChargeDetailDispatch(prevState => ({
            ...prevState,
            manualChargeDetailCntrState: {
                ...prevState.manualChargeDetailCntrState,
                searchCriteria: {
                    ...prevState.manualChargeDetailCntrState.searchCriteria,
                    [dateFields.startField]: startDate,
                    [dateFields.endField]: endDate,
                }
            }
        }))
    }

    const onTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>, fieldName: string, toUpperCase:boolean=false) => {
        const val = toUpperCase ? e.target.value.toString().toUpperCase() : e.target.value
        manualChargeDetailDispatch(prevState => ({
            ...prevState,
            manualChargeDetailCntrState: {
                ...prevState.manualChargeDetailCntrState,
                searchCriteria: {
                    ...prevState.manualChargeDetailCntrState.searchCriteria,
                    [fieldName]: fieldName === 'cntrList' ? 
                        (val&&val.toString().includes(",") ? val.toString().split(",") : [val.toString()]) : 
                        e.target.value.toString(),
                }
            }
        }))
    };

    const onRemoveAllSearchCriteria = () => {
        manualChargeDetailDispatch(prevState => {
            return {
                ...prevState,
                //isShowFindCntrPanel: !prevState.isShowFindCntrPanel,
                viewState: {
                    ...prevState.viewState,
                    //isRead: !prevState.isShowFindCntrPanel,
                },
                manualChargeDetailCntrState: {
                    ...prevState.manualChargeDetailCntrState,
                    searchCriteria: { ...EMPTY_MANUAL_CHARGE_CNTR_SEARCH_CRITERIA }
                }
            }
        })
    }

    const closeCntrCompDialog = () => {
        manualChargeDetailDispatch(prevState => {
            return {
                ...prevState,
                allFormState: {},
                manualChargeDetailCntrs: [],
                isShowFindCntrPanel: false,
                viewState: {
                    ...prevState.viewState,
                    isRead: !prevState.isShowFindCntrPanel,
                },
                manualChargeDetailCntrState: {
                    ...prevState.manualChargeDetailCntrState,
                    searchCriteria: { ...EMPTY_MANUAL_CHARGE_CNTR_SEARCH_CRITERIA },
                    isShowCntrInfoPanel: false,
                    isInitialSearch: true
                }
            }
        })
    }

    const onShowPanel = (cntrNoStr: string) => {
        manualChargeDetailDispatch(prevState => {
            return {
                ...prevState,
                isShowFindCntrPanel: true,
                /*cntrNoStr: cntrNoStr,
                initManChargeDtlCntrs: prevState.initManChargeDtlCntrs,*/
                viewState: {
                    ...prevState.viewState,
                    isRead: !prevState.isShowFindCntrPanel,
                    currentSelectedData: {
                        ...prevState.viewState.currentSelectedData,
                        cntrList: cntrNoStr,
                        initManChargeDtlCntrs: prevState.viewState.currentSelectedData.initManChargeDtlCntrs
                    }
                }
            }
        })
    }

    const onSearch = async(searchCriteria:ManualChargeCntrSearchCriteria, manualChargeDetail:ManualChargeDetailEntity) => {
        manualChargeDetailDispatch(prevState => {
            return {
                ...prevState,
                manualChargeDetailCntrs: [],
                manualChargeDetailCntrState: {
                    ...prevState.manualChargeDetailCntrState,
                    currentSelectedCntrs: []
                }
            }
        })

        const request:ManualChargeDetailCntrRequestEntity = {
            manChargeDtlId: manualChargeDetail.id,
            searchCriteriaBo: searchCriteria
        }

        return await manualChargeDetailRepo.getManualChargeDetailCntr(request).then(data => {
            if (data && data.toString().startsWith("Error:")) {
                manualChargeDetailDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: {"searchManualChargeCntrFail": data.toString()}
                    };
                });
                return {"searchManualChargeCntrFail": data.toString()};
            } else {
                manualChargeDetailDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: {"searchManualChargeCntrSuccess":"successful"},
                        //manualChargeDetailCntrs: data,
                        viewState:{
                            ...prevState.viewState,
                            currentSelectedData: {
                                ...prevState.viewState.currentSelectedData,
                                initManChargeDtlCntrs: data,
                                manChgDtlCntrs: data
                            }
                        },
                        manualChargeDetailCntrState: {
                            ...prevState.manualChargeDetailCntrState,
                            searchCriteria: {
                                ...searchCriteria
                            },
                            currentSelectedCntrs: [],                            
                        }
                    };
                });
                return {"searchManualChargeCntrSuccess":"successful"};
            }
        }).catch(error => {
            manualChargeDetailDispatch(prevState => {
                return {
                    ...prevState,
                    allFormState: {"searchManualChargeCntrFail": error.message}                        
                }
            });
            return {"searchManualChargeCntrFail": error.message};
        })
    }

    const initSearch = async(manualChargeDetail: ManualChargeDetailEntity, cntrNoStr: any, initManChargeDtlCntrs: ManualChargeDetailCntrEntity[]) => {       
        if(cntrNoStr){
            const request:ManualChargeDetailCntrRequestEntity = {
                cntrNoStr: cntrNoStr,
                manChargeDtlId: manualChargeDetail.id,
                initManChargeDtlCntrs:initManChargeDtlCntrs
            }

            return await manualChargeDetailRepo.initManualChargeDetailCntr(request).then(data => {
                if (data && data.toString().startsWith("Error:")) {
                    manualChargeDetailDispatch(prevState => {
                        return {
                            ...prevState,
                            //isShowFindCntrPanel: false,
                            allFormState: {"initManualChargeCntrFail": data.toString()}
                        };
                    });
                    return {"initManualChargeCntrFail": data.toString()};
                } else {
                    manualChargeDetailDispatch(prevState => {
                        return {
                            ...prevState,
                            //isShowFindCntrPanel: false,
                            allFormState: {"initManualChargeCntrSuccess":"successful"},
                            /*initManChargeDtlCntrs: data,
                            manualChargeDetailCntrs: data,*/
                            viewState:{
                                ...prevState.viewState,
                                currentSelectedData: {
                                    ...prevState.viewState.currentSelectedData,
                                    initManChargeDtlCntrs: data,
                                    manChgDtlCntrs: data
                                }
                            },
                            manualChargeDetailCntrState: {
                                ...prevState.manualChargeDetailCntrState,
                                currentSelectedCntrs: [], 
                                isInitialSearch: false                           
                            }
                        };
                    });
                    return {"initManualChargeCntrSuccess":"successful"};
                }
            }).catch(error => {
                manualChargeDetailDispatch(prevState => {
                    return {
                        ...prevState,
                        //isShowFindCntrPanel: false,
                        allFormState: {"initManualChargeCntrFail": error.message}                        
                    }
                });
                return {"initManualChargeCntrFail": error.message};
            })
        }
    }

    const updateSelectedCharges = (rows:any[]) => {           
        manualChargeDetailDispatch(prevState => {
            return {
                ...prevState,
                manualChargeDetailCntrState: {
                    ...prevState.manualChargeDetailCntrState,
                    currentSelectedCntrs: rows,
                    isShowCntrInfoPanel:(rows.length===1?true:false),
                    updateCntrRow:(rows.length===1?rows[0]:EMPTY_MANUAL_CHARGE_DETAIL_CNTR_ENTITY)
                }
            }
        })
    }

    const apply = async(selectedRows:ManualChargeDetailCntrEntity[], manualChargeDetail:ManualChargeDetailEntity, initManChargeDtlCntrs: ManualChargeDetailCntrEntity[]
    ) => {
        const request:ManualChargeDetailCntrRequestEntity = {
            selectedCntrList: selectedRows,
            cntrNoStr: manualChargeDetail.cntrList,
            manChargeDtlId: manualChargeDetail.id,
            initManChargeDtlCntrs: initManChargeDtlCntrs
        }

        return await manualChargeDetailRepo.applyManualChargeDetailCntr(request).then(data => {
            if (data && data.toString().startsWith("Error:")) {
                manualChargeDetailDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: {"applyManualChargeCntrFail": data.toString()}
                    };
                });
                return {"applyManualChargeCntrFail": data.toString()};
            } else {
                
                return manualChargeDetailDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: {"applyManualChargeCntrSuccess":"successful"},
                        isShowFindCntrPanel: false,
                        /*initManChargeDtlCntrs: data.returnVoList as ManualChargeDetailCntrEntity[],
                        manualChargeDetailCntrs: data.returnVoList as ManualChargeDetailCntrEntity[],
                        cntrNoStr: data.returnCntrNoStr as string,*/
                        dtlTotalQty: data.returnTotalQty as number, 
                        viewState: {
                            ...prevState.viewState,
                            isRead: !prevState.isShowFindCntrPanel,
                            currentSelectedData: {
                                ...prevState.viewState.currentSelectedData,
                                cntrList: data.returnCntrNoStr as string,
                                chargeQty: data.returnTotalQty as number,
                                manChgDtlCntrs: data.returnVoList as ManualChargeDetailCntrEntity[],
                                initManChargeDtlCntrs: data.returnVoList as ManualChargeDetailCntrEntity[]
                            }
                        },                               
                        manualChargeDetailCntrState: {
                            ...prevState.manualChargeDetailCntrState,
                            currentSelectedCntrs: [],
                            searchCriteria: { ...EMPTY_MANUAL_CHARGE_CNTR_SEARCH_CRITERIA },
                            isShowCntrInfoPanel: false,
                            isInitialSearch: true
                        }
                    };
                });
                //return {"applyManualChargeCntrSuccess":"successful"};
            }
        }).catch(error => {
            manualChargeDetailDispatch(prevState => {
                return {
                    ...prevState,
                    allFormState: {"applyManualChargeCntrFail": error.message}                        
                }
            });
            return {"applyManualChargeCntrFail": error.message};
        })
    }

    const copyVesselInfo = async (selectedRow:ManualChargeDetailCntrEntity, isArrival: boolean) => {       
        let consortiumCode:string = '';
        let vesselCode:string = '' ;
        let voyageCode:string = '' ;
        let vesselName:string = '' ;
        let handlingTerminal:string = '' ;

        let vesselInfoType = checkVesselInfo(selectedRow);
        if(vesselInfoType === 1 || isArrival)
        {     
            consortiumCode = selectedRow.inConsortiumCode??'';
            vesselCode = selectedRow.inVesselCode??'';
            voyageCode = selectedRow.inVoyageCode??'';
            vesselName = selectedRow.inVesselName??'';
            handlingTerminal = selectedRow.inHandlingTerminal??'';
        }
            
        if(vesselInfoType === 2 || !isArrival)
        {           
            consortiumCode = selectedRow.outConsortiumCode??'';
            vesselCode = selectedRow.outVesselCode??'';
            voyageCode = selectedRow.outVoyageCode??'';
            vesselName = selectedRow.outVesselName??'';
            handlingTerminal = selectedRow.outHandlingTerminal??'';
        }
            
        if(handlingTerminal && vesselCode && voyageCode)
        {
            if(!vesselName)
            {            	
                vesselName = await searchVesselName(handlingTerminal, vesselCode, voyageCode)??'';
            }            
        }

        manualChargeDetailDispatch(prevState => ({
            ...prevState,
            manualChargeDetailCntrState: {
                ...prevState.manualChargeDetailCntrState,
                searchCriteria: {
                    ...prevState.manualChargeDetailCntrState.searchCriteria,
                    co: consortiumCode?.toUpperCase().replace(/\s+/g, ''),
                    vsl: vesselCode?.toUpperCase().replace(/\s+/g, ''),
                    voy: voyageCode?.toUpperCase().replace(/\s+/g, ''),
                    vesselName: vesselName?.toUpperCase().replace(/\s+/g, '') as string,
                }
            }
        }))
    }

    function checkVesselInfo(row: ManualChargeDetailCntrEntity) {
        let vesselInfoType = 0;
        
        if(row.inHandlingTerminal && row.inVesselCode && row.inVoyageCode)
        {
            vesselInfoType += 1;
        }
        if(row.outHandlingTerminal && row.outVesselCode && row.outVoyageCode)
        {
            vesselInfoType += 2;
        }

        return vesselInfoType;
    }

    async function searchVesselName(handlingTerminal: string, vesselCode: string, voyageCode: string) {
        
        return await vesselVoyageMasterRepo.getVesselVoyageMasterSearchVesselName({
            vesselCode: vesselCode,
            voyageCode: voyageCode,
            handlingTerminal: handlingTerminal
        }).then((data) => { 
            if(data.vesselName)
            return data.vesselName;
           
        }).catch((error) => {            
           return '';
        }) 
    }

    const loadDropdownOption = async() => {
        let cntrSizeDropdownOptions: any;
        let cntrTypeDropdownOptions: any;
        let modalityDropdownOptions: any;

        await parameterDetailRepo.getAllParameterDtlsByParameterCode("CNTR_SIZE").then(
            cntrSizes => {
                cntrSizeDropdownOptions = cntrSizes?.map((cntrSize) => ({
                    dropdownLabel: cntrSize.parameterDtlCode,
                    tagLabel: cntrSize.parameterDtlCode,
                    value: cntrSize.parameterDtlCode,
                })) ?? []
            }
        )

        await parameterDetailRepo.getAllParameterDtlsByParameterCode("CNTR_TYPE").then(
            cntrTypes => {
                cntrTypeDropdownOptions = cntrTypes?.map((cntrType) => ({
                    dropdownLabel: cntrType.parameterDtlCode,
                    tagLabel: cntrType.parameterDtlCode,
                    value: cntrType.parameterDtlCode,
                })) ?? []
            }
        )

        await masterDataRepo.getMasterDataByKey(MasterDataType.MODALITY).then(
            modalitys => {
                modalityDropdownOptions = modalitys?.map((modality)=>({
                    dropdownLabel: modality.code,
                    tagLabel: modality.code,
                    value: modality.code,
                })) ?? []                
            }           
        )

        return manualChargeDetailDispatch(prevState => ({
            ...prevState,
            manualChargeDetailCntrState: {
                ...prevState.manualChargeDetailCntrState,
                dynamicOptions:{
                    ...prevState.dynamicOptions,
                    cntrSizeDropdownOptions: cntrSizeDropdownOptions,
                    cntrTypeDropdownOptions: cntrTypeDropdownOptions,
                    modalityArrDropdownOptions: modalityDropdownOptions,
                    modalityDepDropdownOptions: modalityDropdownOptions
                }
            }
        }))
    }

    const onFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any, objName?: string) => {
      let val: any = fieldValue;
      if(objName && objName === 'date_time'){
        val = new Date(val).toISOString();
      }
      if (_.isArray(val)) {
        val = _.uniq(val?.map((item: any) => item.value || item.key));
      }     

      
      return manualChargeDetailDispatch(prevState => { 
        var regex = null;

        if(manChgDtl2dpNumberFieldList.find(e => e === fieldKey)){
            regex = /^\d*(\.\d{0,2})?$/;
            if (!regex.test(val)) { 
                val = prevState.manualChargeDetailCntrState.updateCntrRow[fieldKey] ;
            }
        } else if (manChgDtl4dpNumberFieldList.find(e => e === fieldKey)) {
            regex = /^\d*(\.\d{0,4})?$/;
            if (!regex.test(val)) { 
                val = prevState.manualChargeDetailCntrState.updateCntrRow[fieldKey] ;
            }
        } else if(manChgDtlPositiveIntegerNumberFieldList.find(e => e === fieldKey)){
            var posIntReg = /^\d*$/;
            if (!posIntReg.test(val)) { 
                val = prevState.manualChargeDetailCntrState.updateCntrRow[fieldKey] ;
            }
        }        

        return {
            ...prevState,
            manualChargeDetailCntrState: {
                ...prevState.manualChargeDetailCntrState,                
                updateCntrRow: {
                    ...prevState.manualChargeDetailCntrState.updateCntrRow,
                    [fieldKey]: val                    
                }                
            }            
        }
      });
    }

    const onCancelCntrInfo = () => {
        manualChargeDetailDispatch(prevState => {
            return {
                ...prevState,
                manualChargeDetailCntrState:{
                    ...prevState.manualChargeDetailCntrState,
                    isShowCntrInfoPanel: false
                }
            }
        })
    }

    const onUpdateCntrInfo = (updateRow: ManualChargeDetailCntrEntity) => {        
        manualChargeDetailDispatch(prevState => {
            updateRow.versionStamp = updateRow['versionStamp'] as number + 1;
            const oldDtlCntrs = prevState.viewState.currentSelectedData.manChgDtlCntrs;
                let newDtlCntrs = oldDtlCntrs.filter(dtlCntr => (dtlCntr.id !== null && dtlCntr.id !== updateRow.id) 
                    || (dtlCntr.cntrNo !== updateRow.cntrNo || dtlCntr.cntrVisitId !== updateRow.cntrVisitId));
                    newDtlCntrs.push(updateRow);

            return {
                ...prevState,
                //manualChargeDetailCntrs: newDtlCntrs, 
                viewState: {
                    ...prevState.viewState,
                    currentSelectedData: {
                        ...prevState.viewState.currentSelectedData,
                        manChgDtlCntrs: newDtlCntrs
                    }
                },
                manualChargeDetailCntrState: {
                    ...prevState.manualChargeDetailCntrState, 
                    isShowCntrInfoPanel: false,
                    currentSelectedCntrs: []
                }
            }
        })
    }

    return {
        onCoVslVoyChange: onCoVslVoyChange,
        onMultipleDropdownChange: onMultipleDropdownChange,
        onInputTextChange: onInputTextChange,
        onDateRangeChange: onDateRangeChange,
        onTextAreaChange: onTextAreaChange,
        onRemoveAllSearchCriteria: onRemoveAllSearchCriteria,
        onShowPanel: onShowPanel,
        onSearch: onSearch,
        initSearch: initSearch,
        updateSelectedCharges: updateSelectedCharges,
        apply: apply,
        copyVesselInfo: copyVesselInfo,
        closeCntrCompDialog: closeCntrCompDialog,
        loadDropdownOption: loadDropdownOption,  
        onFieldChange: onFieldChange,
        onCancelCntrInfo: onCancelCntrInfo,
        onUpdateCntrInfo: onUpdateCntrInfo
    }
}


