import { ChargeConstantCollection } from "constants/charge/ChargeConstantCollection";
import { manChgHdrRequiredFieldList, ManualChargeConstant } from "presentation/constant/ManualCharge/ManualChargeConstant";
import { useManualChargeDetailVesselVM } from "presentation/hook/ManualCharge/useManualChargeDetailVesselVM";
import { useManualChargeDetailVM } from "presentation/hook/ManualCharge/useManualChargeDetailVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useManualChargeDetailTracked } from "presentation/store/ManualCharge/ManualChargeDetailProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { useCallback, useMemo, useState } from "react";
import { FieldType, HPHInputMask, IconButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionCross, Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

export const ManualChargeHeaderFieldFormPanel = () => {    
    const [ manualChargeDetailState ] = useManualChargeDetailTracked();
    const manualChargeDetailVM = useManualChargeDetailVM();
    const messageBarVM = useMessageBarVM();    
    const manualChargeDetailVesselVM = useManualChargeDetailVesselVM();
    
    const { isAdd, isEditable, isRead, isSaveClicked, allFormState, editingHeader, isShowDetailInfo, isAdjustmentRead } = manualChargeDetailState.viewState;
    const {currentManualChargeHeader, manualChargeDetails, manualChargeDetailVessels} = manualChargeDetailState;
    const currentManChgHdr = isRead? currentManualChargeHeader : editingHeader;

    const HDR_CONST = ManualChargeConstant.Header;
    const [isLoading, setIsLoading] = useState(false);

    const [anainfoState] = useANAInfoTracked();
    const {allowUpdate} = anainfoState;
    const [isVesselDisable, setIsVesselDisable] = useState(false);


    const isEditDisable = () => {
        if (!(currentManualChargeHeader.hdrState === "NIL")) return true;

        if(manualChargeDetailState.manualChargeDetails && manualChargeDetailState.manualChargeDetails.length > 0){
            const adjType = manualChargeDetailState.manualChargeDetails[0].adjType;
            if(adjType !== ChargeConstantCollection.adjType.NORMAL &&
                adjType !== ChargeConstantCollection.adjType.CANCEL_REBILL &&                
                adjType !== ChargeConstantCollection.adjType.ADJUSTMENT
            ) {
                return true;
            }            
        }
        
        return false;
    }

    const isDisable = () => {
        if (!((isAdd && manualChargeDetails.length>0 && !isShowDetailInfo) || (isEditable && !isRead && manualChargeDetails.length>0 && !isShowDetailInfo))) return true;
        

        return false;
    }

    // const handleClose = useCallback(() => {
    //     manualChargeDetailVM.onCloseScreen();
    // },[manualChargeDetailVM])

    // const handleCancel = useCallback(() => {
    //     manualChargeDetailVM.onCancel();
    // },[manualChargeDetailVM])

    const handleEdit = useCallback(() => {
        manualChargeDetailVM.onEdit(currentManChgHdr, manualChargeDetails);

        setIsVesselDisable(true);
        if(manualChargeDetails.length > 0){
            let vesselCntrNos = "";
            manualChargeDetails.map(
                (manual) => vesselCntrNos = vesselCntrNos +","+ manual.cntrList
            );
            manualChargeDetailVesselVM.initSearch(vesselCntrNos).then((data) => {
                if(manualChargeDetailVessels){
                    setIsVesselDisable(false);
                }
            })
        }
    },[currentManChgHdr, manualChargeDetailVM, manualChargeDetails, manualChargeDetailVesselVM, manualChargeDetailVessels])

    const handleSave = useCallback(() => {
        setIsLoading(true);
        manualChargeDetailVM.onSaveClicked();
        manualChargeDetailVM.onSave(editingHeader,manualChargeDetails,isAdd).then((res) => {
            if(res && res.id){
                setIsLoading(false);
                manualChargeDetailVM.onSearch(res.id as number).then(()=>{
                        setIsLoading(false);
                    }).catch((error) => {                    
                        setIsLoading(false);
                    });
            }else{
                setIsLoading(false);
                if(res && res["mandatoryCheckFail"]){
                    messageBarVM.showError(res["mandatoryCheckFail"]?.toString());
                }               
            }
            
        }).catch(()=>{
            setIsLoading(false);
            messageBarVM.showError('Save Data Failed.');
        });
        
        
    },[editingHeader, isAdd, manualChargeDetailVM, manualChargeDetails, messageBarVM])

    const handleCoVslVoy = () => {
        if(manualChargeDetailVessels.length === 1){
            manualChargeDetailVesselVM.coVslVoy(manualChargeDetailVessels).then((data) => {})  
        }else{
            let vesselCntrNos = "";
            manualChargeDetails.map(
                (manual) => vesselCntrNos = vesselCntrNos +","+ manual.cntrList
            );
            manualChargeDetailVesselVM.onShowPanel(vesselCntrNos);
        }
    }
    
    const memoState = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgHdr?.hdrState || ''}
                fieldValue={currentManChgHdr?.hdrState}
                fieldLabel={HDR_CONST.STATE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'hdrState'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />    
        </div>
    , [currentManChgHdr?.hdrState, HDR_CONST.STATE, isSaveClicked])
    
    const memoRefNo = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentManChgHdr?.manChargeNo || ''}
                fieldValue={currentManChgHdr?.manChargeNo}
                fieldLabel={HDR_CONST.REF_NO}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'manChargeNo'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} /> 
        </div>
    , [HDR_CONST.REF_NO, currentManChgHdr?.manChargeNo, isSaveClicked])

    const memoOpsDate = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <DatePickerComponent
                    disabled={isRead || isAdjustmentRead}
                    label={HDR_CONST.OPS_DATE}
                    width="150px"
                    date={currentManChgHdr?.opsDate}
                    fieldName="opsDate"
                    errorMessage={allFormState?allFormState["opsDate"]:""}
                    onDateChange={manualChargeDetailVM.onHeaderFieldChange}/>
        </div>
    , [isRead, isAdjustmentRead, HDR_CONST.OPS_DATE, currentManChgHdr?.opsDate, allFormState, manualChargeDetailVM.onHeaderFieldChange])
    
    const memoCoVslVoy = useMemo(() =>
        <div className='im-flex-row-item' style={{marginBottom:"24px"}}>
            <HPHInputMask
                label={HDR_CONST.CO_VSL_VOY}
                width={'330px'}          
                disabled={isRead || isAdjustmentRead}
                optional={true}
                value={{
                    co: currentManChgHdr?.consortiumCode ?? '',
                    vsl: currentManChgHdr?.vesselCode ?? '',
                    voy: currentManChgHdr?.voyageCode ?? ''
                }}
                fieldConfiguration={{
                    co: { maxLength: 60, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                    vsl: { maxLength: 10, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                    voy: { maxLength: 10, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                }}
                onDataChange={(e: any) => manualChargeDetailVM.onCoVslVoyChange(e, {
                    co: 'consortiumCode',
                    vsl: 'vesselCode',
                    voy: 'voyageCode'
                })}/>
        </div>
    , [HDR_CONST.CO_VSL_VOY, currentManChgHdr?.consortiumCode, currentManChgHdr?.vesselCode, currentManChgHdr?.voyageCode, isAdjustmentRead, isRead, manualChargeDetailVM])

    const memoBillToCompany = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgHdr?.billToCompany || ''}
                fieldValue={currentManChgHdr?.billToCompany}
                fieldLabel={HDR_CONST.BILL_TO_COMPANY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'billToCompany'}
                maxLength={60}
                requiredFieldList={manChgHdrRequiredFieldList}
                options={manualChargeDetailState.dynamicOptions.billToCompDropdownOptions}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    manualChargeDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />    
        </div>
    , [HDR_CONST.BILL_TO_COMPANY, allFormState, currentManChgHdr?.billToCompany, isAdjustmentRead, isRead, isSaveClicked, manualChargeDetailState.dynamicOptions.billToCompDropdownOptions, manualChargeDetailVM])

    
    const memoOperatingTml = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgHdr?.operatingTml || ''}
                fieldValue={currentManChgHdr?.operatingTml}
                fieldLabel={HDR_CONST.OPERATING_TML}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'operatingTml'}
                maxLength={60}
                requiredFieldList={manChgHdrRequiredFieldList}
                options={manualChargeDetailState.dynamicOptions.operatingTmlDropdownOptions}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    manualChargeDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [HDR_CONST.OPERATING_TML, allFormState, currentManChgHdr?.operatingTml, isAdjustmentRead, isRead, isSaveClicked, manualChargeDetailState.dynamicOptions.operatingTmlDropdownOptions, manualChargeDetailVM])

    const memoArrDatetime = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <DatePickerComponent
                    disabled={isRead || isAdjustmentRead}
                    label={HDR_CONST.ARR_DATETIME}
                    width="150px"
                    date={currentManChgHdr?.etb}
                    fieldName="etb" 
                    optional={true}       
                    errorMessage={allFormState?allFormState["arrDepRangeDate"]:""}
                    onDateChange={manualChargeDetailVM.onHeaderFieldChange}/>
        </div>
    , [isRead, isAdjustmentRead, HDR_CONST.ARR_DATETIME, currentManChgHdr?.etb, allFormState, manualChargeDetailVM.onHeaderFieldChange])
    
    
    const memoDepDatetime = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <DatePickerComponent
                    disabled={isRead || isAdjustmentRead}
                    label={HDR_CONST.DEP_DATETIME}
                    width="150px"
                    date={currentManChgHdr?.etd}
                    fieldName="etd"
                    optional={true}
                    errorMessage={allFormState?allFormState["etd"]:""}
                    onDateChange={manualChargeDetailVM.onHeaderFieldChange}/>
        </div>
    , [isRead, isAdjustmentRead, HDR_CONST.DEP_DATETIME, currentManChgHdr?.etd, allFormState, manualChargeDetailVM.onHeaderFieldChange])
    
    const memoServiceCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgHdr?.serviceCode || ''}
                fieldValue={currentManChgHdr?.serviceCode}
                fieldLabel={HDR_CONST.SERVICE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'serviceCode'}
                maxLength={60}
                requiredFieldList={manChgHdrRequiredFieldList}
                options={manualChargeDetailState.dynamicOptions.serviceCodeDropdownOptions}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    manualChargeDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [HDR_CONST.SERVICE, allFormState, currentManChgHdr?.serviceCode, isAdjustmentRead, isRead, isSaveClicked, manualChargeDetailState.dynamicOptions.serviceCodeDropdownOptions, manualChargeDetailVM])

    const memoYourRef = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgHdr?.yourRef || ''}
                fieldValue={currentManChgHdr?.yourRef}
                fieldLabel={HDR_CONST.YOUR_REF}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'yourRef'}
                maxLength={60}
                requiredFieldList={manChgHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    manualChargeDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [allFormState, isRead, isAdjustmentRead, currentManChgHdr?.yourRef, HDR_CONST.YOUR_REF, isSaveClicked, manualChargeDetailVM])

    const memoOurRef = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgHdr?.ourRef || ''}
                fieldValue={currentManChgHdr?.ourRef}
                fieldLabel={HDR_CONST.OUR_REF}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'ourRef'}
                maxLength={60}
                requiredFieldList={manChgHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    manualChargeDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [allFormState, isRead, isAdjustmentRead, currentManChgHdr?.ourRef, HDR_CONST.OUR_REF, isSaveClicked, manualChargeDetailVM])

    
    const memoPoNo = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgHdr?.poNo || ''}
                fieldValue={currentManChgHdr?.poNo}
                fieldLabel={HDR_CONST.PO_NO}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'poNo'}
                maxLength={60}
                requiredFieldList={manChgHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    manualChargeDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [allFormState, isRead, isAdjustmentRead, currentManChgHdr?.poNo, HDR_CONST.PO_NO, isSaveClicked, manualChargeDetailVM])

    const memoLoa = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgHdr?.loa?.toString() || ''}
                fieldValue={currentManChgHdr?.loa?.toString()}
                fieldLabel={HDR_CONST.LOA}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'loa'}
                maxLength={60}
                isShowMissingError={false}
                requiredFieldList={manChgHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    manualChargeDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [allFormState, isRead, isAdjustmentRead, currentManChgHdr?.loa, HDR_CONST.LOA, isSaveClicked, manualChargeDetailVM])


    const memoSoa = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgHdr?.soa || ''}
                fieldValue={currentManChgHdr?.soa}
                fieldLabel={HDR_CONST.SOA}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'soa'}
                maxLength={6}
                isTextFieldCapitalize={true}
                requiredFieldList={manChgHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    manualChargeDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [allFormState, isRead, isAdjustmentRead, currentManChgHdr?.soa, HDR_CONST.SOA, isSaveClicked, manualChargeDetailVM])


    const memoSlVslCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgHdr?.slVesselCode || ''}
                fieldValue={currentManChgHdr?.slVesselCode}
                fieldLabel={HDR_CONST.SL_VSL_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'slVesselCode'}
                maxLength={60}
                requiredFieldList={manChgHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    manualChargeDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [allFormState, isRead, isAdjustmentRead, currentManChgHdr?.slVesselCode, HDR_CONST.SL_VSL_CODE, isSaveClicked, manualChargeDetailVM])

    const memoSlIbVoyCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgHdr?.slIbVoyageCode || ''}
                fieldValue={currentManChgHdr?.slIbVoyageCode}
                fieldLabel={HDR_CONST.SL_IB_VOY_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'slIbVoyageCode'}
                maxLength={60}
                requiredFieldList={manChgHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    manualChargeDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [allFormState, isRead, isAdjustmentRead, currentManChgHdr?.slIbVoyageCode, HDR_CONST.SL_IB_VOY_CODE, isSaveClicked, manualChargeDetailVM])

    const memoSlObVoyCode = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgHdr?.slObVoyageCode || ''}
                fieldValue={currentManChgHdr?.slObVoyageCode}
                fieldLabel={HDR_CONST.SL_OB_VOY_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'slObVoyageCode'}
                maxLength={60}
                requiredFieldList={manChgHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    manualChargeDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [allFormState, isRead, isAdjustmentRead, currentManChgHdr?.slObVoyageCode, HDR_CONST.SL_OB_VOY_CODE, isSaveClicked, manualChargeDetailVM])


    const memoDesc1 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgHdr?.hdrDesc1 || ''}
                fieldValue={currentManChgHdr?.hdrDesc1}
                fieldLabel={HDR_CONST.HDR_DESC1}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'hdrDesc1'}
                maxLength={60}
                requiredFieldList={manChgHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    manualChargeDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [allFormState, isRead, isAdjustmentRead, currentManChgHdr?.hdrDesc1, HDR_CONST.HDR_DESC1, isSaveClicked, manualChargeDetailVM])



    const memoDesc2 = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgHdr?.hdrDesc2 || ''}
                fieldValue={currentManChgHdr?.hdrDesc2}
                fieldLabel={HDR_CONST.HDR_DESC2}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'hdrDesc2'}
                maxLength={60}
                requiredFieldList={manChgHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    manualChargeDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [allFormState, isRead, isAdjustmentRead, currentManChgHdr?.hdrDesc2, HDR_CONST.HDR_DESC2, isSaveClicked, manualChargeDetailVM])

    const memoChgType = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"150px", marginBottom:"24px"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isAdjustmentRead}
                isShowOptional={true}
                readOnlyValue={currentManChgHdr?.chargeType || ''}
                fieldValue={currentManChgHdr?.chargeType}
                fieldLabel={HDR_CONST.CHARGE_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeType'}
                options={manualChargeDetailState.dynamicOptions.chargeTypeDropdownOptions}
                maxLength={60}
                requiredFieldList={manChgHdrRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    manualChargeDetailVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [HDR_CONST.CHARGE_TYPE, allFormState, currentManChgHdr?.chargeType, isAdjustmentRead, isRead, isSaveClicked, manualChargeDetailState.dynamicOptions.chargeTypeDropdownOptions, manualChargeDetailVM])


    return <>
        <div className='side-form-content-left-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                <Sidebarheader>
                        <SidebarTitle>{currentManChgHdr.manChargeNo??'Header'}</SidebarTitle>
                        <SidebarActionCross>
                                    {(!isAdd && (isEditable && isRead)) &&
                                        <>
                                            {
                                                (
                                                    (isEditable && allowUpdate)
                                                )
                                                && <IconButton fileName='Icon-pen' size='medium' disabled={isEditDisable()} toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />
                                            }
                                        </>}
                                        {/*<IconButton 
                                            fileName="Icon-cross" 
                                            size="medium" 
                                            disabled={!isRead && isShowDetailInfo} 
                                            toolTipArrow={false} 
                                            toolTipPlacement="left" 
                                            toolTipText={isAdd || isRead ? 'Close' : 'Cancel'} 
                                            onClick={isAdd || isRead ? handleClose : handleCancel} />*/}

                                    {((isAdd || (isEditable && !isRead))) &&
                                        <>
                                        {/* <IconButton fileName="Icon-reset" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={handleReset} /> */}
                                            {
                                                (
                                                    (isAdd) ||
                                                    (isEditable && !isRead)
                                                )
                                                && <IconButton fileName="Icon-tick" size="medium" disabled={isDisable()} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={handleSave} />
                                            }
                                        </>}
                                </SidebarActionCross>

                    </Sidebarheader>
                </div>
            </div>
            
            {isLoading && <Loader Indicator="Spinner" size="Medium" />}

            <div className={'add-edit-form'} style={{ height:'78vh', maxHeight:'78vh' , overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <CriteriaItemContainer>
                            {memoState}{memoRefNo}
                        </CriteriaItemContainer>        
                        <CriteriaItemContainer>
                            {memoChgType}
                        </CriteriaItemContainer>         
                        <CriteriaItemContainer>
                            {memoOpsDate}{memoBillToCompany}
                        </CriteriaItemContainer>              
                        <CriteriaItemContainer>
                            {memoCoVslVoy}
                            {(!isRead && !isAdjustmentRead) && <div className='password-eye manual-dtl-coVslVoy'>
                            <IconButton fileName="Icon-more" tooltipDisable = {true} size="medium" disabled={isVesselDisable} onClick={handleCoVslVoy}/></div>}
                        </CriteriaItemContainer>         
                        <CriteriaItemContainer>
                            {memoOperatingTml}{memoServiceCode}
                        </CriteriaItemContainer>         
                        <CriteriaItemContainer>
                            {memoArrDatetime}{memoDepDatetime}
                        </CriteriaItemContainer>         
                        <CriteriaItemContainer>
                            {memoYourRef}{memoOurRef}
                        </CriteriaItemContainer>         
                        <CriteriaItemContainer>
                            {memoPoNo}{memoLoa}
                        </CriteriaItemContainer>         
                        <CriteriaItemContainer>
                            {memoSoa}{memoSlVslCode}
                        </CriteriaItemContainer>       
                        <CriteriaItemContainer>
                            {memoSlIbVoyCode}{memoSlObVoyCode}
                        </CriteriaItemContainer>     
                        <CriteriaItemContainer>
                            {memoDesc1}{memoDesc2}
                        </CriteriaItemContainer>     
                    </div>                  
                </div>
            </div>
        </div>
    </>
}